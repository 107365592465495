import {
  JobOfferAvailabilityEnum,
  SessionDataFragment,
  SessionReportedIssueTypeEnum,
} from '@vette/data-access';
import {
  SessionStatusBadge,
  JobGroupPath,
  Text,
  QuestionMarkTooltip,
} from '@vette/ui-components';
import { formatDateOnly, formatTime } from '@vette/frontend-utils';
import { session } from '@vette/common-utils';
import { Row } from 'antd';
import { Mutable } from 'utility-types';

const readonlyColumns = [
  {
    title: 'Name',
    width: '136px',
    dataIndex: 'applicant',
    key: 'name',
    fixed: true,
    render: (applicant: SessionDataFragment['applicant']) =>
      `${applicant.firstName} ${applicant.lastName}`,
  },
  {
    title: 'Status',
    width: '144px',
    fixed: true,
    key: 'status',
    render: ({
      canceled,
      recommended,
      reportedIssueType,
      reportedIssueDetail,
      status,
    }: SessionDataFragment) =>
      recommended === null && reportedIssueType ? (
        <Row align="middle">
          <SessionStatusBadge
            size="small"
            recommended={recommended}
            canceled={canceled}
            status={status}
          />
          {/* TODO: replace with QuestionMarkTooltip component */}
          <QuestionMarkTooltip
            title={
              reportedIssueType === SessionReportedIssueTypeEnum.OTHER
                ? reportedIssueDetail
                : session.getIssueDescriptionByIssueType(reportedIssueType)
                    ?.text
            }
          />
        </Row>
      ) : (
        <SessionStatusBadge
          size="small"
          recommended={recommended}
          canceled={canceled}
          status={status}
        />
      ),
  },
  {
    title: 'Job',
    width: '147px',
    key: 'job',
    render: ({ jobOffer }: SessionDataFragment) => {
      const { jobGroup } = jobOffer;

      return (
        <JobGroupPath
          isJob
          enabled={jobOffer.availability === JobOfferAvailabilityEnum.ACTIVE}
          showFullPath
          name={jobOffer.title}
          path={jobGroup.path}
          isDeleted={!!jobGroup.deletedAt}
        />
      );
    },
  },
  {
    title: 'Timestamp',
    width: '193px',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: SessionDataFragment['createdAt']) => (
      <>
        <Text $size={15}>{formatDateOnly(createdAt)}</Text>
        <br />
        <Text $size={12}>{formatTime(createdAt)}</Text>
      </>
    ),
  },
] as const;

export const columns = readonlyColumns as Mutable<typeof readonlyColumns>;
