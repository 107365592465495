import React from 'react';
import * as S from './styles';
import { TooltipProps as AntdTooltipProps } from 'antd';

type Props = AntdTooltipProps & {
  compact?: boolean;
  fullWidth?: boolean;
};

export const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({
  compact,
  fullWidth,
  ...antdProps
}) => {
  return (
    <S.Tooltip
      {...antdProps}
      overlayClassName={compact ? 'ant-tooltip-compact' : undefined}
    >
      <S.HoverableButton $fullWidth={fullWidth}>
        {antdProps.children}
      </S.HoverableButton>
    </S.Tooltip>
  );
};
