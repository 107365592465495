import React from 'react';
import * as helpers from './helpers';
import * as S from './styles';
import { Tooltip, TagProps, Row } from 'antd';
import { SessionStatusEnum } from '@vette/data-access';
import { QuestionMarkTooltip } from '../../common';

type Props = {
  recommended?: boolean | null;
  canceled?: boolean | null;
  size?: S.StatBadgeSize;
  clickable?: boolean;
  status?: SessionStatusEnum | null;
  tooltipText?: string;
} & TagProps;

export const SessionStatusBadge: React.FC<Props> = ({
  recommended,
  canceled,
  size,
  clickable,
  status,
  tooltipText,
  ...props
}) => {
  const textLabel = helpers
    .getTextLabelByRecommendedValue(recommended, status)
    .toUpperCase();

  const result = textLabel ? (
    <>
      <S.StatBadge
        $size={size || 'small'}
        clickable={clickable}
        backgroundColor={helpers.getColorByRecommendedValue(
          recommended,
          status
        )}
        text={
          canceled ? (
            <S.InnerWrap>
              <Tooltip title="This interview was canceled by a vetter due to technical difficulties. Regardless of that we've attempted to preserve as much information as possible.">
                <S.WarnIcon />
              </Tooltip>{' '}
              {textLabel}
            </S.InnerWrap>
          ) : (
            textLabel
          )
        }
        {...props}
      />
    </>
  ) : null;

  return result ? (
    <Row align="middle">
      {result}
      {tooltipText && <QuestionMarkTooltip title={tooltipText} />}
    </Row>
  ) : null;
};
