import styled, { css } from 'styled-components';
import { Card as AntCard, Row } from 'antd';

export const Card = styled(AntCard)<{
  $height?: string;
  $width?: string;
  $noMaxWidth: boolean;
  $disableMargins: boolean;
  $disablePaddings: boolean;
  $disableFillAvailableHeight: boolean;
}>`
  background: ${({ theme }) => theme.styles.gradient};
  border-radius: 0;
  min-height: max-content;
  padding: 12px;
  height: 100%;
  & .ant-card-body {
    height: 100%;
  }
  & .ant-card-head {
    border-bottom: none;
  }

  ${({ $disableMargins }) => {
    return (
      $disableMargins &&
      css`
        margin: 0 !important;
      `
    );
  }}

  ${({ $disablePaddings }) => {
    return (
      $disablePaddings &&
      css`
        padding: 0 !important;
      `
    );
  }}

  @media (min-width: 576px) {
    max-width: ${({ $noMaxWidth }) => ($noMaxWidth ? 'unset' : '924px')};
    padding: 50px 40px 40px 40px;
    border-radius: 18px;
    height: ${({ $height }) => ($height ? $height : 'auto')};
    width: ${({ $width }) => ($width ? $width : 'auto')};
    & .ant-card-body {
      padding: 0;
      ${({ $disableFillAvailableHeight }) =>
        // height:fill-available messes up the layout on Safari
        !$disableFillAvailableHeight &&
        css`
          height: fill-available;
        `};
    }
    margin: 36px;
  }

  @media (max-height: 576px) and (orientation: landscape) {
    border-radius: 0;
  }
`;

export const FullHeightRow = styled(Row)<{ $innerRowFullWidth: boolean }>`
  ${({ $innerRowFullWidth }) =>
    $innerRowFullWidth
      ? css`
          display: block;
          width: 100%;
        `
      : css`
          display: flex;
        `}
  height: 100%;
`;
