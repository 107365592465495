import styled from 'styled-components';

export const TableWrap = styled.div`
  .ant-table-thead > tr > th:nth-child(2) {
    border-right: 1px solid ${({ theme }) => theme.colors.grey20};
  }

  .ant-table-tbody > tr > td:nth-child(2) {
    border-right: 1px solid ${({ theme }) => theme.colors.grey20};
  }
`;
