import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 249px;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 24px;

  @media print {
    display: none;
  }
`;

export const SubMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.img<{ hasStatusToggle?: boolean }>`
  height: 20px;
  margin: 36px;
  cursor: pointer;
  margin-right: ${({ hasStatusToggle }) =>
    hasStatusToggle ? '20px ' : '36px'};
`;

export const MenuItem = styled.div<{ active: boolean }>`
  display: flex;
  flex: 0 1;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;
  padding-left: 40px;
  min-height: 60px;
  cursor: pointer;

  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.white60};
  font-weight: 600;
  font-size: 15px;
  & > svg {
    color: ${({ theme, active }) =>
      active ? theme.colors.white : theme.colors.white60};
    margin-right: 14px;
  }

  &:hover {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.08) 0%,
      rgba(0, 0, 0, 0.08) 53.91%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;
