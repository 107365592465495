import styled from 'styled-components';
import { Tooltip as AntdTooltip } from 'antd';

export const Tooltip = styled(AntdTooltip)``;

export const HoverableButton = styled.button.attrs({
  className: 'ant-btn',
})<{ $fullWidth?: boolean }>`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 300;
  box-shadow: none;
  font-size: 15px;
  padding: 0;
  margin: 0;
  height: auto;
  ${({ $fullWidth }) => $fullWidth && `width: 100%;`}

  :hover {
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }

  :active {
    order: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }

  :focus {
    order: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }

  :selected {
    order: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }
`;
