import { Tooltip } from 'antd';
import React from 'react';
import * as S from './styles';
import { Space } from '@vette/ui-components';

type Props = React.PropsWithChildren<{
  src: string;
  label: string;
  imageOffsetY?: string;
  loading: boolean;
  tooltip?: string;
  backgroundSize?: 'contain';
  grayscale?: boolean;
  opacity?: number;
}>;

export const DashboardCard: React.FC<Props> = ({
  src,
  label,
  imageOffsetY,
  tooltip,
  children,
  loading,
  backgroundSize,
  grayscale = false,
  opacity,
}) => {
  const body = (
    <S.Label $align={true} $size={18} $weight={600}>
      {label}
    </S.Label>
  );
  return (
    <S.SkeletonCard active loading={loading}>
      <S.Card>
        <S.ImageWrap
          $src={src}
          offsetY={imageOffsetY}
          backgroundSize={backgroundSize}
          grayscale={grayscale}
          opacity={opacity}
        />
        <S.CardBody>
          {tooltip ? (
            <Space direction="horizontal" size={5} align="center">
              {body}
              <Tooltip title={tooltip}>
                <S.QuestionIcon />
              </Tooltip>
            </Space>
          ) : (
            body
          )}
          {children}
        </S.CardBody>
      </S.Card>
    </S.SkeletonCard>
  );
};
