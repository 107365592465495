import { useInternalVetterSessionsQuery } from '@vette/data-access';
import { useCustomRouter } from '@vette/frontend-utils';
import { Button, Table, theme, Title } from '@vette/ui-components';
import { Row } from 'antd';
import Link from 'next/link';
import React from 'react';
import { columns } from './columns';
import { SkeletonTable } from './Empty/SkeletonTable';

import * as S from './styles';

type Props = {
  userId: string;
  clientId: string;
};

const PAGE_SIZE = 4;

export const ApplicantsList: React.FC<Props> = ({ userId, clientId }) => {
  const { getPath, pages } = useCustomRouter();

  const { data, loading } = useInternalVetterSessionsQuery({
    variables: {
      clientId,
      pagination: { take: PAGE_SIZE, skip: 0 },
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const sessions = data?.sessions?.data || [];

  const header = (
    <Row justify="space-between">
      <Title level={3} $color={theme.colors.grey100}>
        Applicants
      </Title>
      <Link href={getPath(pages.client.applicants)}>
        <Button type="default">View All</Button>
      </Link>
    </Row>
  );

  if (sessions.length === 0) {
    return <SkeletonTable userId={userId} header={header} loading={loading} />;
  }
  return (
    <S.TableWrap>
      <Table
        pagination={false}
        dataSource={sessions}
        columns={columns}
        scroll={{ x: 'max-content' }}
        header={header}
        getRowLink={record =>
          record?.id
            ? getPath(pages.client.sessions_view({ sessionId: record.id }))
            : '#'
        }
      />
    </S.TableWrap>
  );
};
