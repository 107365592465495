import { getApolloClient } from './apollo';
import {
  ClientExportDetailedDataDocument,
  ClientExportDetailedDataQuery,
  ClientExportPerformanceDataDocument,
  ClientExportPerformanceDataQuery,
} from '@vette/data-access';
import { message } from './message';

const DETAILED_DATA_HEADER = [
  'Created At',
  'Client',
  'Job',
  'Interviewer',
  'Job Location',
  'Applicant',
  'Phone Number',
  'Channel',
  'Vetted',
  'Status',
  'Passed by Vetter',
];

const PERFORMANCE_DATA_HEADER = [
  'Client',
  'Job',
  'Vetted',
  'Passed by Vetter',
  'Internal Interviewer',
  'External Interviewer',
];

const quoteTextWithComma = (text: string) =>
  text.includes(',') ? `"${text}"` : text;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const exportCSV = (rows: any[][], fileName = 'vette_data') => {
  const csvContent =
    'data:text/csv;charset=utf-8,' +
    rows
      .map(row =>
        row
          .map(val => (typeof val === 'string' ? quoteTextWithComma(val) : val))
          .join(',')
      )
      .join('\n');

  const encodedURI = encodeURI(csvContent);
  const escapedEncodedURI = encodedURI.replace(/#/g, '%23');
  const link = document.createElement('a');
  link.setAttribute('href', escapedEncodedURI);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);

  link.click();
};

export const importCSV = (file: File): Promise<string[][]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', e => {
      const data = e.target?.result as string;
      const rows = data.split(/\r\n|\n|\r/).map(r => r.trim());
      const rowsWithColumns = rows.map(r =>
        r.split(/,|, | , | ,/).map(c => c.trim())
      );
      resolve(rowsWithColumns);
    });

    reader.addEventListener('error', () => {
      reject(new Error('CSV file could not be read'));
    });

    reader.readAsText(file);
  });
};

export const exportDetailedData = async (clientId: string) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.query<ClientExportDetailedDataQuery>({
      query: ClientExportDetailedDataDocument,
      variables: { clientId },
    });

    if (!data.clientExportDetailedData) throw new Error('No data');

    const orderedRows = data.clientExportDetailedData.map(
      ({
        createdAt,
        client,
        job,
        interviewer,
        location,
        applicant,
        phone,
        channel,
        vetted,
        status,
        passed,
      }) => [
        createdAt,
        client,
        job,
        interviewer,
        location,
        applicant,
        phone,
        channel,
        vetted,
        status,
        passed,
      ]
    );

    exportCSV(
      [DETAILED_DATA_HEADER, ...orderedRows],
      'detailed data export - ' + new Date().toISOString()
    );
  } catch (e) {
    console.log((e as Error).message);
    message.error('Failed to export detailed data');
  }
};

export const exportPerformanceData = async (clientId: string) => {
  const apolloClient = getApolloClient();

  try {
    const { data } = await apolloClient.query<ClientExportPerformanceDataQuery>(
      {
        query: ClientExportPerformanceDataDocument,
        variables: { clientId },
      }
    );
    if (!data.clientExportPerformanceData) throw new Error('No data');
    const orderedRows = data.clientExportPerformanceData.map(
      ({
        client,
        job,
        vetted,
        passed,
        internalInterviewer,
        externalInterviewer,
      }) => [
        client,
        job,
        vetted,
        passed,
        internalInterviewer,
        externalInterviewer,
      ]
    );
    exportCSV(
      [PERFORMANCE_DATA_HEADER, ...orderedRows],
      'performance data export - ' + new Date().toISOString()
    );
  } catch (e) {
    message.error('Failed to export performance data');
  }
};
