export const arrayBuilder = <TItem>() => {
  const array: TItem[] = [];

  const self = {
    add: (item: TItem) => {
      array.push(item);
      return self;
    },
    build: () => array,
    addIf: (condition: boolean, item: TItem | undefined) => {
      if (condition && item) return self.add(item);
      return self;
    },
  };

  return self;
};
