import { TooltipProps } from 'antd';
import * as S from './styles';
import { Tooltip } from '../atoms';

type Props = {} & TooltipProps;

export const QuestionMarkTooltip: React.FC<Props> = tooltipProps => {
  return (
    <Tooltip {...tooltipProps}>
      <S.QuestionMarkIcon />
    </Tooltip>
  );
};
