import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  .ant-skeleton-button {
    height: 14px;
  }
  & .ant-skeleton-element .ant-skeleton-button:not(.ant-skeleton-button-round) {
    border-radius: 0;
  }

  & .ant-table-tbody > tr > td {
    padding: 8px;
  }
`;

export const OverlayWrapper = styled.div<{
  $loading?: boolean;
  $visible: boolean;
}>`
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  margin: 100px 0;
  background: rgb(255, 255, 255, 0.5);
  background: radial-gradient(
      13.96% 177.49% at 50.05% 53.67%,
      ${({ theme }) => theme.colors.white} 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      48.43% 48.53% at 50.05% 53.67%,
      ${({ theme }) => theme.colors.white} 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      48.43% 48.53% at 50.05% 53.67%,
      ${({ theme }) => theme.colors.white} 0%,
      rgba(255, 255, 255, 0) 100%
    );
  top: 0;
  z-index: ${({ theme, $visible }) =>
    theme.styles.zIndexPriority.topOverMost + ($visible ? 1 : 0)};

  opacity: ${({ $loading, $visible }) => ($loading ? 0 : $visible ? 1 : 0)};
  transition: opacity 0.8s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img``;

export const Link = styled.a`
  color: #fff;
  text-decoration: underline;

  &:hover {
    color: #fff;
  }
`;
