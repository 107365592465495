import ArrowBack from '@mui/icons-material/ArrowBack';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {
  firebase,
  isAdminAsClient,
  removeAdminPath,
  useAppContext,
  useCustomRouter,
  message,
} from '@vette/frontend-utils';
import Link from 'next/link';
import React from 'react';
import { OnlineOfflineSwitch } from '../../../vetter';
import * as S from './styles';

export type SideMenuType = {
  title?: string;
  route?: string;
  icon?: React.ReactNode;
  hasStatusToggle?: boolean;
  fullLogo?: boolean;
};

export const Sider: React.FC<SideMenuType> = ({
  hasStatusToggle,
  fullLogo,
}) => {
  const { router, getPath } = useCustomRouter();
  const { logout, claims } = firebase.useFirebase();

  const appContext = useAppContext();
  const sideMenu = appContext?.sideMenu;

  const takeAction = async (actionType?: string) => {
    switch (actionType) {
      case 'logout':
        await logout();
        message.destroy();
        router.replace('/login');
        break;

      case 'goToSettings':
        router.replace('/settings');
        break;

      default:
        return;
    }
  };

  return (
    <S.Wrapper>
      <div>
        <Link passHref href={sideMenu[0]?.route}>
          <S.Logo
            src={fullLogo ? '/fullLogo.svg' : '/logo.svg'}
            hasStatusToggle={hasStatusToggle}
          />
        </Link>
        {hasStatusToggle && (
          <firebase.FirebaseClaims required={{ id: true }}>
            {({ id }) => id && <OnlineOfflineSwitch userId={id} />}
          </firebase.FirebaseClaims>
        )}
        {sideMenu
          .filter(menuItem => !menuItem.bottom)
          .filter(
            menuItem =>
              typeof menuItem.shouldDisplay !== 'function' ||
              menuItem.shouldDisplay({ claims })
          )
          .map(({ title, route, icon }) => (
            <Link href={getPath(route)} passHref key={title}>
              <S.MenuItem
                aria-label={title}
                active={
                  removeAdminPath(router.asPath).startsWith(route) ||
                  (route.includes('/applicants') &&
                    router.asPath.includes('/sessions/view'))
                }
              >
                {icon}
                {title}
              </S.MenuItem>
            </Link>
          ))}
      </div>
      {isAdminAsClient(router.asPath) ? (
        <S.SubMenuWrapper>
          <Link href={getPath('/team-members')} key={'Team Members'} passHref>
            <S.MenuItem
              onClick={() => takeAction('goToTeamMembers')}
              active={removeAdminPath(router.asPath).startsWith(
                '/team-members'
              )}
            >
              <PersonAddAlt1Icon />
              Team Members
            </S.MenuItem>
          </Link>
          <Link href={'/clients'} passHref>
            <S.MenuItem active={false}>
              <ArrowBack />
              Clients
            </S.MenuItem>
          </Link>
        </S.SubMenuWrapper>
      ) : (
        <S.SubMenuWrapper>
          {sideMenu
            .filter(menuItem => menuItem.bottom)
            .filter(
              menuItem =>
                typeof menuItem.shouldDisplay !== 'function' ||
                menuItem.shouldDisplay({ claims })
            )
            .map(({ icon, title, route, actionType }) => {
              return (
                <Link href={getPath(route)} key={title} passHref>
                  <S.MenuItem
                    aria-label={title}
                    onClick={() => takeAction(actionType)}
                    active={removeAdminPath(router.asPath).startsWith(route)}
                  >
                    {icon}
                    {title}
                  </S.MenuItem>
                </Link>
              );
            })}
        </S.SubMenuWrapper>
      )}
    </S.Wrapper>
  );
};
