import styled from 'styled-components';
import { Switch as AntdSwitch } from 'antd';

export const Switch = styled(AntdSwitch)<{ color?: string }>`
  &.ant-switch {
    height: 38px;
    width: 115px;
    .ant-switch-handle {
      border-radius: 50%;
      height: 20px;
      width: 20px;
      top: 9px;
      left: 9px;
    }
    .ant-switch-inner {
      font-weight: 600;
      margin-left: 20px;
    }
    .ant-switch-handle::before {
      border-radius: 50%;
    }
  }
  &.ant-switch-checked .ant-switch-inner {
    margin-left: 10px;
  }
  &.ant-switch-checked .ant-switch-handle {
    height: 20px;
    width: 20px;
    left: calc(100% - 20px - 9px);
  }
  ${({ color }) =>
    color &&
    `
    &.ant-switch-checked {
      background-color: ${color}!important;
    }
  `}
`;
