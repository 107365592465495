import { EmptyProps } from 'antd';
import React from 'react';

import * as S from './styles';

type Props = {} & EmptyProps;

export const Empty: React.FC<Props> = props => {
  return (
    <S.Wrap>
      <S.Empty {...props} />
    </S.Wrap>
  );
};

export const NoData = () => (
  <S.Wrapper>
    <S.Image src="/images/empty.svg" />
    <S.Label>No data</S.Label>
  </S.Wrapper>
);

export const NoResults = () => (
  <S.Wrapper>
    <S.Image src="/images/eyes.svg" />
    <S.Label>
      Oops! We have not found any results for your search query.
    </S.Label>
  </S.Wrapper>
);
