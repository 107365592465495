import React from 'react';
import ReactECharts from 'echarts-for-react';
import { Space, Text, theme } from '@vette/ui-components';
import { Legend } from './Legend';
import { array } from '@vette/common-utils';
import { useInternalVetterStatisticsQuery } from '@vette/data-access';
import * as S from './styles';

type Props = { clientId: string; userId: string };

export const StatsChart: React.FC<Props> = ({ clientId, userId }) => {
  const { data } = useInternalVetterStatisticsQuery({
    variables: { clientId, userId },
  });

  const {
    all = 0,
    passed = 0,
    failed = 0,
    incomplete = 0,
  } = data?.internalVetterStatistics || {};

  return (
    <Space direction="horizontal">
      <S.ChartWrapper>
        <ReactECharts
          option={{
            tooltip: {
              show: false,
            },
            legend: {
              show: false,
            },
            series: [
              {
                type: 'pie',
                radius: ['52%', '65%'],
                avoidLabelOverlap: false,
                showEmptyCircle: true,
                emptyCircleStyle: {
                  color: theme.colors.grey20,
                },
                itemStyle: {
                  borderRadius: 20,
                  borderColor: theme.colors.white,
                  borderWidth: 2,
                },
                label: {
                  show: false,
                },
                emphasis: {
                  label: {
                    show: false,
                  },
                },
                labelLine: {
                  show: false,
                },
                data: array
                  .arrayBuilder()
                  .addIf(!!passed, {
                    value: passed,
                    name: 'Passed Applicants',
                    itemStyle: { color: theme.colors.green40 },
                  })
                  .addIf(!!failed, {
                    value: failed,
                    name: 'Failed Applicants',
                    itemStyle: { color: theme.colors.red40 },
                  })
                  .addIf(!!incomplete, {
                    value: incomplete,
                    name: 'Incomplete Interviews',
                    itemStyle: { color: theme.colors.grey40 },
                  })
                  .build(),
              },
            ],
          }}
        />
        <S.TotalWrapper>
          <Space direction="vertical">
            <Text $centered $size={14} $weight={600}>
              Total Vettes
            </Text>
            <Text
              $centered
              $size={32}
              $weight={600}
              style={{ lineHeight: '24px' }}
            >
              {all}
            </Text>
          </Space>
        </S.TotalWrapper>
      </S.ChartWrapper>
      <Legend stats={{ passed, failed, incomplete }} />
    </Space>
  );
};
