import React from 'react';
import { Table } from '@vette/ui-components';
import { columns } from './columns';

import * as S from './styles';
import { Overlay } from './Overlay';

type Props = { userId: string; loading?: boolean } & React.ComponentProps<
  typeof Table
>;

export const SkeletonTable: React.FC<Props> = ({ userId, header, loading }) => {
  return (
    <S.Wrapper>
      <Table
        pagination={false}
        header={header}
        columns={columns({ loading })}
        dataSource={[...new Array(4)].map((_, i) => ({ key: `skeleton_${i}` }))}
      />
      <Overlay userId={userId} loading={loading} />
    </S.Wrapper>
  );
};
