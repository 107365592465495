import styled from 'styled-components';
import QuestionCircleFilled from '@ant-design/icons/QuestionCircleFilled';

export const QuestionMarkIcon = styled(QuestionCircleFilled)`
  color: ${({ theme }) => theme.colors.grey60};
  font-size: 12px;

  &:hover {
    cursor: pointer;
  }
`;
