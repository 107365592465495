import { session as sessionEntity } from '@vette/common-utils';
import { SessionStatusEnum, SessionsFilterInput } from '@vette/data-access';
import { theme } from '../../common/theme';

export type RecommendedValueType = Pick<
  SessionsFilterInput,
  'status' | 'recommended' | 'flagged'
>;

export const textLabelMappedToRecommendedValue = {
  All: undefined,
  Passed: {
    recommended: true,
    status: [SessionStatusEnum.SESSION_FINISHED_SUCCESSFULLY],
  },
  Failed: {
    recommended: false,
    status: [SessionStatusEnum.SESSION_FINISHED_SUCCESSFULLY],
  },
  Incomplete: {
    status: sessionEntity.FAILED_STATUSES as SessionStatusEnum[],
  },
  'Knockout failed': {
    status: [SessionStatusEnum.APPLICANT_DID_NOT_PASS_KNOCKOUT],
  },
  Flagged: {
    flagged: true,
  },
} satisfies Record<string, RecommendedValueType | undefined>;

export const getColorByRecommendedValue = (
  recommended?: boolean | null,
  status?: SessionStatusEnum | null
) => {
  if (status && sessionEntity.IN_PROGRESS_STATUSES.some(s => s === status)) {
    return theme.colors.purple40;
  }

  switch (recommended) {
    case true: {
      return theme.colors.green40;
    }
    case false: {
      return theme.colors.red40;
    }
    default: {
      return theme.colors.grey40;
    }
  }
};

export const getTextLabelByRecommendedValue = (
  recommended?: boolean | null,
  status?: SessionStatusEnum | null
): string => {
  if (status && sessionEntity.IN_PROGRESS_STATUSES.some(s => s === status)) {
    return 'In progress';
  }

  if (
    status === SessionStatusEnum.APPLICANT_DID_NOT_PASS_KNOCKOUT &&
    recommended === false
  ) {
    return 'Knockout failed';
  }

  if (recommended === true) {
    return 'Passed';
  }

  if (recommended === false) {
    return 'Failed';
  }

  if (!recommended) {
    return 'Incomplete';
  }

  return 'unhandled';
};
