import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import FolderIconOutlined from '@mui/icons-material/FolderOpenOutlined';
import { RoundBadge, Text, theme, Tooltip } from '../../common';
import * as S from './styles';

type CommonProps = {
  name: string;
  path: string;
  showFullPath?: boolean;
  isDeleted?: boolean;
  compact?: boolean;
};

type Props = CommonProps & {
  isJob: boolean;
  enabled: boolean;
};

export const JobGroupPath: React.FC<Props> = ({
  name,
  path,
  showFullPath,
  isDeleted,
  compact,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [textOverflows, setTextOverflows] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const pathParts = useMemo(() => {
    return path.split('/').slice(1, -1);
  }, [path]);

  const tableLink = () => {
    if (!containerRef.current) return;

    // Walk to the parent of container until there's a table-cell-content class
    let parent = containerRef.current.parentElement;
    while (parent && !parent.classList.contains('table-cell-content')) {
      parent = parent.parentElement;
    }

    if (!parent) return;

    // find a sibling with class table-cell-link
    return parent.parentElement?.querySelector('a.table-cell-link' as 'a');
  };

  useLayoutEffect(() => {
    const container = containerRef.current;

    const content = contentRef.current;

    if (content && container) {
      // Max Width on content disables overflow
      // We are doing ellipsis on text if it's close to full width of the container
      const gracefulLengthPX = 20;
      setTextOverflows(
        content.scrollWidth + gracefulLengthPX > container.clientWidth
      );

      const link = tableLink();
      if (!link) return;
      link.onmouseover = () => setTooltipVisible(true);
      link.onmouseout = () => setTooltipVisible(false);
    }
  }, [path]);

  useLayoutEffect(() => {
    if (!containerRef.current || !textOverflows) return;
    let nearestTrParent = containerRef.current.parentElement;
    while (nearestTrParent && nearestTrParent.tagName !== 'TR') {
      nearestTrParent = nearestTrParent.parentElement;
    }
    if (!nearestTrParent) return;

    if (tooltipVisible) nearestTrParent.style.zIndex = '779';
    else
      setTimeout(() => {
        if (!nearestTrParent) return;
        nearestTrParent.style.zIndex = '0';
      }, 300);
  }, [tooltipVisible, textOverflows]);

  const icon = useMemo(() => {
    if (props.isJob) {
      return props.enabled && !isDeleted ? (
        <RoundBadge
          className="round-badge"
          color={theme.colors.green40}
          size={14}
          $marginRight={8}
        />
      ) : (
        <RoundBadge
          className="round-badge"
          color={theme.colors.grey40}
          size={14}
          $marginRight={8}
        />
      );
    }

    return <FolderIconOutlined />;
  }, [props.isJob, props.enabled, isDeleted]);

  const prefix = useMemo(() => {
    if (!showFullPath || pathParts.length === 1) return;

    return (
      <>
        <S.JobNameCol $maxWidth="104px" $color={theme.colors.grey50}>
          <FolderIconOutlined />
          <S.JobNameCol $minWidth="16px">
            <Text $color={theme.colors.grey50}>{pathParts[0]}</Text>
          </S.JobNameCol>
        </S.JobNameCol>
        {pathParts.length > 1 && (
          <S.JobNameCol $minWidth="6px" $color={theme.colors.grey50}>
            <Tooltip title={pathParts.join(' / ')}>
              <Text $color={theme.colors.grey50}>
                /{pathParts.length > 2 && ' ... /'}
              </Text>
            </Tooltip>
          </S.JobNameCol>
        )}
      </>
    );
  }, [pathParts, showFullPath]);

  const jobNameText = useMemo(
    () => (
      <Text ref={contentRef}>
        {name}
        {isDeleted ? ' (deleted)' : ''}
      </Text>
    ),
    [name, isDeleted]
  );

  const jobName = useMemo(
    () => (
      <S.JobNameCol
        $maxWidth={showFullPath && pathParts.length > 1 ? '50%' : undefined}
        ref={containerRef}
      >
        {icon}
        {(!showFullPath || pathParts.length < 2) && textOverflows ? (
          <>
            <Tooltip
              compact
              title={name}
              trigger={['']}
              visible={tooltipVisible}
              getTooltipContainer={() => tableLink() || document.body}
            ></Tooltip>
            {jobNameText}
          </>
        ) : (
          jobNameText
        )}
      </S.JobNameCol>
    ),
    [
      name,
      pathParts,
      icon,
      jobNameText,
      showFullPath,
      textOverflows,
      tooltipVisible,
    ]
  );

  return (
    <S.JobNameRow $compact={compact} $isDeleted={isDeleted}>
      {prefix}
      {jobName}
    </S.JobNameRow>
  );
};
