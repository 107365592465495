import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

type Props = {
  fallback?: React.ReactNode;
  children: (query: ParsedUrlQuery) => React.ReactNode;
  allowEmpty?: boolean;
};

export const RouterQuery: React.FC<Props> = ({
  children,
  fallback,
  allowEmpty = false,
}) => {
  const router = useRouter();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (allowEmpty) {
      setIsReady(true);
    }
  }, [router.query, allowEmpty]);

  if (!isReady && isEmpty(router.query)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{fallback || <></>}</>;
  }

  return <>{children(router.query)}</>;
};
