import React from 'react';
import Link from 'next/link';
import { useRowLinkContext } from './RowLinkContext';

type Props = {};

const InternalCell: React.FC<React.PropsWithChildren<Props>> = props => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const link = useRowLinkContext();
  const { children, ...restCellProps } = props;
  return (
    <td {...restCellProps}>
      <div className="table-cell-content">{children}</div>
      {link ? <Link className="table-cell-link" href={link} /> : null}
    </td>
  );
};

export const Cell = React.memo(InternalCell, () => true);
