import {
  useClientDashboardQuery,
  useClientUpdatedSubscription,
  useCreateBillingSessionMutation,
} from '@vette/data-access';
import { useRouter } from 'next/router';
import {
  exportDetailedData,
  exportPerformanceData,
  hooks,
} from '@vette/frontend-utils';
import { Text } from '@vette/ui-components';
import { Space } from 'antd';
import React, { useMemo } from 'react';
import { Dropdown } from 'antd';
import { Menu } from 'antd';
import { DashboardCard } from './DashboardCard';
import * as S from './styles';

type Props = {
  clientId: string;
  userId: string;
  canManageBilling: boolean;
};

export const Dashboard: React.FC<Props> = ({
  clientId,
  userId,
  canManageBilling,
}) => {
  const { data, loading, refetch } = useClientDashboardQuery({
    variables: { clientId, userId },
  });

  const router = useRouter();

  const [createBillingSession] = useCreateBillingSessionMutation();

  const handleClick = async () => {
    const result = await createBillingSession({
      variables: {
        userId,
      },
    });

    if (result.data?.createBillingSession?.url) {
      router.replace(result.data.createBillingSession.url);
    }
  };

  hooks.useThrottleSubscription(refetch, 2000, useClientUpdatedSubscription, {
    variables: {
      clientId,
    },
  });

  const {
    available = 0,
    engaged = 0,
    passed = 0,
    vetted = 0,
  } = data?.clientDashboard || {};

  const stripeCustomerId =
    data?.client?.__typename === 'Client'
      ? data.client.stripeCustomerId
      : undefined;

  const cardData = useMemo(
    () => [
      {
        label: 'Engaged',
        value: engaged,
        src: '/images/engaged-photo.svg',
        opacity: 0.6,
        grayscale: engaged === 0 || engaged === null,
        tooltip:
          'Engaged applicants are the applicants who applied to a job and were engaged via SMS.',
      },
      {
        label: 'Interviewed',
        value: vetted,
        src: '/images/interviewed-photo.svg',
        opacity: 0.6,
        grayscale: vetted === 0 || vetted === null,
        tooltip:
          'Interviewed applicants are the applicants who completed the interview or failed the knockout questions.',
      },
      {
        label: 'Passed',
        value: passed,
        src: '/images/passed-photo.svg',
        grayscale: passed === 0 || passed === null,
        opacity: 1,
        tooltip:
          'Passed applicants are the applicants who passed the interview.',
      },
      {
        label: 'External Vettes',
        ...(!stripeCustomerId && {
          value: available,
        }),
      },
    ],
    [available, engaged, passed, vetted, stripeCustomerId]
  );
  type ExportKey = 'SESSION-DATA' | 'AGGREGATED-DATA';

  const newEnum: { [K in ExportKey]: K } = {
    'SESSION-DATA': 'SESSION-DATA',
    'AGGREGATED-DATA': 'AGGREGATED-DATA',
  };

  const exportData = (key: ExportKey) => {
    if (key === newEnum['SESSION-DATA']) {
      exportDetailedData(clientId);
    } else if (key === newEnum['AGGREGATED-DATA']) {
      exportPerformanceData(clientId);
    }
  };

  const menu = (
    <Menu
      onClick={e => exportData(e.key as ExportKey)}
      items={[
        {
          label: 'Export session data',
          key: 'SESSION-DATA',
        },
        {
          label: 'Export aggregated data',
          key: 'AGGREGATED-DATA',
        },
      ]}
    />
  );

  return (
    <>
      <S.Header className="dashboard-manager">
        <S.Heading>
          {data?.user?.firstName ? `Hi ${data?.user?.firstName}` : 'Dashboard'}
        </S.Heading>
        <Dropdown overlay={menu} trigger={['click']}>
          <S.Button type="primary">
            Export data
            {<S.DropdownOutlined />}
          </S.Button>
        </Dropdown>
      </S.Header>
      <S.Cards>
        {cardData.map(({ label, value, src, grayscale, opacity, tooltip }) =>
          label !== 'External Vettes' ? (
            <DashboardCard
              key={label}
              loading={loading}
              label={label}
              src={src ?? ''}
              backgroundSize="contain"
              tooltip={tooltip}
              grayscale={!loading && grayscale}
              opacity={grayscale ? opacity : undefined}
            >
              <S.PrimaryText>{value}</S.PrimaryText>
            </DashboardCard>
          ) : (
            <S.SkeletonCard active loading={loading} key={label}>
              <S.lastCard>
                <Space direction="vertical">
                  <S.Label $color="white" $size={18} $weight={600}>
                    {label}
                  </S.Label>
                  <Text
                    $color="white"
                    $weight={400}
                    $size={15}
                    onClick={
                      stripeCustomerId && canManageBilling
                        ? handleClick
                        : undefined
                    }
                    style={
                      stripeCustomerId && canManageBilling
                        ? { cursor: 'pointer' }
                        : {}
                    }
                  >
                    {!stripeCustomerId
                      ? 'Your available current credit for external Vettes.'
                      : ' Click here to manage your subscription'}
                  </Text>
                </Space>
                <Space direction="vertical" align="baseline">
                  <S.PrimaryText $color="white" $size={36}>
                    {value}
                  </S.PrimaryText>
                  <Space direction="horizontal" size={5} align="center">
                    <S.InfoIcon />

                    <S.InfoText $color="white" $size={10} $weight={500}>
                      {!stripeCustomerId
                        ? 'To top up please contact Vette'
                        : 'For billing questions, please contact'}
                      <br />
                      <u>
                        {!stripeCustomerId
                          ? 'sales@vette.io'
                          : 'billing@vette.io'}
                      </u>
                    </S.InfoText>
                  </Space>
                </Space>
                <S.ImageWrap
                  $src={'/images/ellipse-photo.svg'}
                  backgroundSize="contain"
                  offsetX="100%"
                />
              </S.lastCard>
            </S.SkeletonCard>
          )
        )}
      </S.Cards>
    </>
  );
};
