import React from 'react';
import {
  useUpdatePresenceMutation,
  UserPresenceEnum,
} from '@vette/data-access';
import { Button, Space, Text, theme, Title } from '@vette/ui-components';
import { message, useUser } from '@vette/frontend-utils';

import * as S from './styles';

type Props = { userId: string; loading?: boolean };

export const Overlay: React.FC<Props> = ({ userId, loading }) => {
  const { user, rules, loading: queryLoading } = useUser();

  const [updateUserPresence, { loading: mutationLoading }] =
    useUpdatePresenceMutation();

  const isOnline = user?.presence === UserPresenceEnum.ONLINE;

  const areQueriesLoading = queryLoading || loading;
  return (
    <>
      <S.OverlayWrapper $loading={areQueriesLoading} $visible={!isOnline}>
        <Space direction="vertical" size={24} align="center">
          <Space direction="vertical" size={10} align="center">
            <S.Icon src="/images/rocket.svg" />
            <Space direction="vertical" size={4}>
              <Title $align="center" level={3}>
                Get Started
              </Title>
              <Text $centered $size={15} $color={theme.colors.grey40}>
                Go online to start receiving Vettes.
              </Text>
            </Space>
          </Space>

          <Button
            onClick={() => {
              !rules.beAbleToGoOnline
                ? message.error(
                    <>
                      Oops! Please finish your profile setup in{' '}
                      <S.Link href="/settings">Settings</S.Link> to go online
                    </>
                  )
                : updateUserPresence({
                    variables: {
                      userId,
                      presence: UserPresenceEnum.ONLINE,
                    },
                  });
            }}
            loading={mutationLoading}
            type="primary"
          >
            Go online
          </Button>
        </Space>
      </S.OverlayWrapper>
      <S.OverlayWrapper $loading={areQueriesLoading} $visible={isOnline}>
        <Space direction="vertical" size={24} align="center">
          <Space direction="vertical" size={10} align="center">
            <S.Icon src="/images/glasshour.svg" />
            <Space direction="vertical" size={4}>
              <Title $align="center" level={3}>
                We are waiting for new applicants...
              </Title>
              <Text $centered $size={15} $color={theme.colors.grey40}>
                Once a new interview request comes in, we&apos;ll notify you
                automatically on this screen.
              </Text>
            </Space>
          </Space>
        </Space>
      </S.OverlayWrapper>
    </>
  );
};
