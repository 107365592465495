import styled from 'styled-components';
import { Empty as AntdEmpty } from 'antd';

import { Text } from '../Typography';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 22px;
`;
export const Empty = styled(AntdEmpty)``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Label = styled(Text)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.grey40};
`;

export const Image = styled.img`
  height: 64px;
`;
