import React from 'react';
import { TagProps } from 'antd';

import * as S from './styles';
import { theme } from '../../theme';

type StatBadgeProps = {
  text: string | React.ReactNode;
  backgroundColor?: string;
  clickable?: boolean;
  $height?: number;
} & TagProps;

export const StatBadge: React.FC<StatBadgeProps> = ({
  text,
  clickable = false,
  backgroundColor = theme.colors.green40,
  ...props
}) => {
  return (
    <S.StatBadge
      $clickable={clickable}
      $backgroundColor={backgroundColor}
      {...props}
    >
      {text}
    </S.StatBadge>
  );
};
