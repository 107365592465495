import { Skeleton } from 'antd';
import styled, { css } from 'styled-components';

type WrapperProps = {
  $disableHoverAnimation?: boolean;
  $forbidExpand?: boolean;
  $gap?: number;
  $rowPadding?: number;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex: 1;
  gap: ${({ $gap }) => (typeof $gap === 'number' ? `${$gap}px` : '24px')};
  flex-direction: column;

  & .ant-table-tbody > tr > td,
  & .ant-table-thead > tr > th {
    border-color: ${({ theme }) => theme.colors.grey20};
  }

  & .ant-table-wrapper {
    width: 100%;

    & .ant-table-thead > tr > th::before {
      content: none !important;
    }
    & .ant-table-thead > tr > th {
      font-weight: 600;
    }

    & .ant-table-row {
      z-index: 0;
    }

    .ant-table-row:hover > td,
    .ant-table-cell-row-hover {
      background: ${({ theme, $disableHoverAnimation }) =>
        $disableHoverAnimation ? 'none' : theme.colors.yellow10};
    }

    .ant-table-row-level-0 {
      position: relative;
    }

    button {
      z-index: 777;
    }

    .ant-select {
      z-index: 777;
    }

    .ant-table-cell-fix-left {
      z-index: 778;
    }

    .ant-table-placeholder .table-cell-content {
      width: 100% !important;
      font-size: 15px;
      font-weight: 400;
    }

    & .table-cell-content {
      width: 100% !important;
      font-size: 15px;
      font-weight: 400;
    }

    // Extra line above first visible table row
    // Usefull when we do not show table header
    .ant-table-row:nth-of-type(2) > td {
      border-top: 1px solid ${({ theme }) => theme.colors.grey10};
    }

    .ant-table-tbody > tr > td {
      padding: ${({ $rowPadding }) =>
        typeof $rowPadding === 'number'
          ? `${$rowPadding}px ${$rowPadding}px`
          : undefined};

      ${({ $forbidExpand }) =>
        $forbidExpand &&
        css`
          .ant-table-row-expand-icon {
            display: none;
          }
        `}
    }

    .ant-table-tbody > tr > td a.table-cell-link {
      padding: 16px 16px;
      display: table-cell;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .ant-table-expanded-row-fixed {
      width: 100% !important;
    }
  }
`;

export const Placeholder = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 53px;
`;

export const PlaceholderSkeleton = styled(Skeleton).attrs({
  title: true,
  paragraph: false,
  active: true,
})<{ isHeader?: boolean }>`
  display: flex;
  flex: 1;
  height: 60vh;
  .ant-skeleton-title {
    height: ${({ isHeader }) => (isHeader ? 54 : 1)}px;
    border-radius: ${({ isHeader }) => (isHeader ? '12px 12px 0 0' : 0)};
    display: flex;
    flex: 1;
    margin-top: 0;
  }
`;
