import { createContext, useContext } from 'react';

const RowLinkContext = createContext<string | null>(null);

export const useRowLinkContext = () => {
  return useContext(RowLinkContext);
};

export const RowLinkProvider: React.FC<
  React.PropsWithChildren<{ link: string | null }>
> = ({ link, children }) => {
  return (
    <RowLinkContext.Provider value={link}>{children}</RowLinkContext.Provider>
  );
};
