import styled from 'styled-components';

export const RoundBadge = styled.div<{
  $color: string;
  $size: number;
  $marginRight?: number;
}>`
  flex: none;
  height: ${({ $size }) => $size}px;
  width: ${({ $size }) => $size}px;
  border-radius: ${({ $size }) => Math.ceil($size / 2)}px;
  background-color: ${({ $color }) => $color};
  ${({ $marginRight }) => $marginRight && `margin-right: ${$marginRight}px;`}
`;
