import type {
  OperationVariables,
  SubscriptionHookOptions,
} from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { throttle } from 'lodash';
import { useMemo } from 'react';

type Subscription<TSub, TVariables extends OperationVariables> = (
  baseOptions?: SubscriptionHookOptions<TSub, TVariables>
) => {
  variables?: TVariables | undefined;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any | undefined;
  error?: ApolloError | undefined;
};

export const useThrottleSubscription = <
  TSub,
  TVariables extends OperationVariables
>(
  throttleFn: () => void,
  throttleWait: number,
  subscription: Subscription<TSub, TVariables>,
  subscriptionOptions?: Parameters<Subscription<TSub, TVariables>>[0]
) => {
  const refetch = useMemo(
    () => throttle(throttleFn, throttleWait),
    [throttleFn, throttleWait]
  );
  subscription({
    fetchPolicy: 'no-cache',
    onSubscriptionData: () => {
      refetch();
    },
    ...subscriptionOptions,
  });
};
