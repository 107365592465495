import React from 'react';
import * as S from './styles';

type RoundBadgeProps = {
  color: string;
  size: number;
} & React.ComponentProps<typeof S['RoundBadge']>;

export const RoundBadge: React.FC<RoundBadgeProps> = ({
  color,
  size,
  ...props
}) => {
  return <S.RoundBadge {...props} $color={color} $size={size} />;
};
