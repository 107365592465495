import React from 'react';
import { SessionStatusEnum } from '@vette/data-access';
import { Space } from '@vette/ui-components';
import { LegendItem } from './LegendItem';
import * as S from './styles';

type Props = {
  stats: {
    passed: number | null;
    failed: number | null;
    incomplete: number | null;
  };
};

export const Legend: React.FC<Props> = ({ stats }) => {
  return (
    <S.LegendWrapper>
      <Space direction="vertical" size={18}>
        <LegendItem
          status={SessionStatusEnum.SESSION_FINISHED_SUCCESSFULLY}
          recommended={true}
          stat={stats.passed}
          tooltip="Applicants you recommended."
        />
        <LegendItem
          status={SessionStatusEnum.SESSION_FINISHED_SUCCESSFULLY}
          recommended={false}
          stat={stats.failed}
          tooltip="Applicants you did not recommend."
        />
        <LegendItem
          status={SessionStatusEnum.SESSION_FINISHED_WITH_AN_ISSUE}
          recommended={null}
          stat={stats.incomplete}
          tooltip="The interview was interrupted and couldn't be completed."
        />
      </Space>
    </S.LegendWrapper>
  );
};
