import styled from 'styled-components';

export const ChartWrapper = styled.div`
  width: 18%;
  min-width: 282px;
  position: relative;
`;

export const TotalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
