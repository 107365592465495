import styled, { css } from 'styled-components';
import WarningIcon from '@mui/icons-material/Warning';
import { StatBadge as BaseStatBadge } from '../../common/atoms/StatBadge';

export type StatBadgeSize = 'large' | 'small';
export const StatBadge = styled(BaseStatBadge)<{ $size: StatBadgeSize }>`
  ${({ $size }) =>
    $size === 'large' &&
    css`
      padding: 13.5px 24px;
      border-radius: 24px;
    `}
`;

export const InnerWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const WarnIcon = styled(WarningIcon)`
  width: 14px;
  max-height: 20px;
`;
