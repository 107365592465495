import React from 'react';
import { CardProps } from 'antd';

import * as S from './styles';

type Props = {
  height?: string;
  width?: string;
  noMaxWidth?: boolean;
  innerRowFullWidth?: boolean;
  disableMargins?: boolean;
  disablePaddings?: boolean;
  disableFillAvailableHeight?: boolean;
} & CardProps;

export const Card: React.FC<Props> = ({
  width,
  height,
  noMaxWidth = false,
  innerRowFullWidth = false,
  disableMargins = false,
  disablePaddings = false,
  disableFillAvailableHeight = false,
  children,
  ...props
}) => {
  return (
    <S.Card
      {...props}
      $height={height}
      $width={width}
      $noMaxWidth={noMaxWidth}
      $disableMargins={disableMargins}
      $disablePaddings={disablePaddings}
      $disableFillAvailableHeight={disableFillAvailableHeight}
    >
      <S.FullHeightRow $innerRowFullWidth={innerRowFullWidth}>
        {children}
      </S.FullHeightRow>
    </S.Card>
  );
};
