import styled from 'styled-components';
import { Card } from '@vette/ui-components';

export const Wrapper = styled.div`
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 36px;
  overflow-y: auto;
  z-index: 1;
`;

export const CoolCard = styled(Card)`
  &.ant-card {
    overflow: hidden;
    border: none;

    & .ant-card-body {
      border-radius: 18px;
      background: linear-gradient(
          270deg,
          rgba(118, 62, 217, 0.2) 0%,
          rgba(118, 62, 217, 0) 100%
        ),
        ${({ theme }) => theme.colors.white};
    }
  }
`;

export const CirclesImage = styled.img.attrs({
  src: '/images/circlesIllustration.svg',
})<{ $active?: boolean }>`
  position: absolute;
  width: 53%;
  min-height: 100%;

  right: 5%;
  opacity: ${({ $active }) => (!$active ? 0.96 : 1)};
  filter: ${({ $active }) => (!$active ? 'blur(80%)' : 'blur(0%)')};

  transition: all 0.3s ease-in-out;

  @media (max-width: 1280px) {
    width: 35%;
  }
`;

export const LoadingHidden = styled.div<{ isLoading: boolean }>`
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  transition: opacity 0.3s ease-in-out;
`;

export const Profile = styled.a`
  position: absolute;
  display: flex;
  align-items: center;
  right: 36px;
  top: 36px;
  gap: 12px;

  & > img {
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.white};
  }
`;
