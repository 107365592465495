import { format, parseISO, addMinutes } from 'date-fns';

export const formatDate = (value: string) =>
  value ? format(parseISO(value), 'MM/dd/yyyy hh:mm aa') : '';

export const formatDateOnly = (value: string) =>
  value ? format(parseISO(value), 'MM/dd/yyyy') : '';

export const formatTime = (value: string) =>
  value ? format(parseISO(value), 'hh:mm aa') : '';

export const formatInUTC = (value: Date | string) => {
  const date = new Date(value);
  return `${format(
    addMinutes(date, date.getTimezoneOffset()),
    'dd/MM/yyyy hh:mm aa'
  )} UTC`;
};
