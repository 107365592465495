import React, { useCallback, useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
import {
  useUpdatePresenceMutation,
  useVetterUpdatedSubscription,
  UserPresenceEnum,
} from '@vette/data-access';
import { useUser } from '@vette/frontend-utils';
import { Switch, theme } from '../../common';
import NoSleep from 'nosleep.js';

type Props = {
  userId: string;
};

export const OnlineOfflineSwitch: React.FC<Props> = ({ userId }) => {
  const { rules: userRules, user, loading } = useUser();
  const noSleepRef = useRef<NoSleep>();
  const [updateUserPresence] = useUpdatePresenceMutation();

  useVetterUpdatedSubscription({
    variables: {
      userId,
    },
  });

  const checked =
    user?.presence === UserPresenceEnum.ONLINE ||
    user?.presence === UserPresenceEnum.BUSY;

  const updateVetter = useCallback(async () => {
    const newStatus = checked
      ? user?.presence === UserPresenceEnum.BUSY
        ? UserPresenceEnum.ONLINE
        : UserPresenceEnum.OFFLINE
      : UserPresenceEnum.ONLINE;

    if (checked) {
      await noSleepRef.current?.enable();
    } else {
      noSleepRef.current?.disable();
    }

    await updateUserPresence({
      variables: {
        userId,
        presence: newStatus,
      },

      optimisticResponse: {
        __typename: 'Mutation',
        updateUserPresence: {
          __typename: 'User',
          id: userId,
          presence: newStatus,
        },
      },
    });
  }, [updateUserPresence, userId, checked, user?.presence]);

  useEffect(() => {
    const noSleepLock = new NoSleep();
    noSleepRef.current = noSleepLock;
  });

  const reasonNotToGoOnline =
    userRules.beAbleToGoOnline === false
      ? userRules.unableToGoOnlineReason
      : undefined;

  return (
    <Tooltip
      title={(() => {
        if (reasonNotToGoOnline === 'MISSING_PROFILE_INFO') {
          return 'Before you start Vetting you must finalize your profile setup.';
        } else if (reasonNotToGoOnline === 'NOT_APPROVED_BY_ADMIN') {
          return 'Your account must be approved before you are able to start Vetting';
        }
        return user?.presence === UserPresenceEnum.ONLINE
          ? 'While you are online, you can expect to receive interview requests'
          : 'While you are offline, you will not get incoming Vettes.';
      })()}
    >
      <Switch
        disabled={reasonNotToGoOnline !== undefined}
        aria-label={checked ? 'Go Offline' : 'Go Online'}
        onClick={updateVetter}
        loading={loading}
        checkedChildren={
          user?.presence === UserPresenceEnum.BUSY
            ? UserPresenceEnum.BUSY
            : UserPresenceEnum.ONLINE
        }
        unCheckedChildren={UserPresenceEnum.OFFLINE}
        checked={checked}
        color={
          user?.presence === UserPresenceEnum.BUSY
            ? theme.colors.yellow100
            : undefined
        }
      />
    </Tooltip>
  );
};
