import React from 'react';
import { Sider } from './Sider';
import { UserPresenceEnum } from '@vette/data-access';
import {
  hooks,
  isAdminAsClient,
  useCustomRouter,
  useUser,
} from '@vette/frontend-utils';
import { useTheme } from 'styled-components';
import { PageSkeleton } from '../atoms';

import * as S from './styles';

type Props = React.PropsWithChildren<{
  loading?: boolean;
  hasStatusToggle?: boolean;
  fullLogo?: boolean;
  overflowY?: 'auto';
}>;

export const DashboardLayout: React.FC<Props> = ({
  children,
  loading,
  hasStatusToggle,
  fullLogo,
  overflowY,
}) => {
  const { router } = useCustomRouter();
  const { isClientManager } = hooks.useRole();

  const isEligibleForInterview =
    !isClientManager && !isAdminAsClient(router.asPath);

  const { user } = useUser();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();

  const darkMode =
    !isClientManager &&
    user?.presence === UserPresenceEnum.OFFLINE &&
    theme.config.allowDarkMode;

  return (
    <S.Wrapper hasSider>
      <S.DarkModeOverlay $darkMode={darkMode} $404={fullLogo} />
      <Sider
        fullLogo={fullLogo}
        hasStatusToggle={hasStatusToggle && isEligibleForInterview}
      />
      <S.Layout $overflowY={overflowY}>
        {loading ? <PageSkeleton /> : children}
      </S.Layout>
    </S.Wrapper>
  );
};
