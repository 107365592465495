import styled, { css } from 'styled-components';
import { Tag } from 'antd';

export const StatBadge = styled(Tag)<{
  $backgroundColor: string;
  $clickable?: boolean;
  $height?: number;
}>`
  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `}
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  background: ${({ $backgroundColor }) => $backgroundColor};
  min-width: 20px;
  ${({ $height }) => $height && `height: ${$height}px;`}
  text-align: center;
  font-weight: 600;

  -webkit-print-color-adjust: exact;
`;
