import { SessionReportedIssueType, SessionStatus } from '@prisma/client';

// For interview now button states
export const WAITING_FOR_VETTER_CONNECT_STATUSES = [
  SessionStatus.LOOKING_FOR_VETTER,
  SessionStatus.LOOKING_FOR_VETTER_TIMED_OUT,
  SessionStatus.WAITING_FOR_VETTER_TO_ACCEPT_SESSION_REQUEST,
  SessionStatus.VETTER_REQUEST_TIMED_OUT,
  SessionStatus.VETTER_REJECTED_SESSION_REQUEST,
];

// 1. Session statuses
export const REPEATABLE_STATUSES = [
  SessionStatus.LOOKING_FOR_VETTER,
  SessionStatus.LOOKING_FOR_VETTER_TIMED_OUT,
  SessionStatus.WAITING_FOR_VETTER_TO_ACCEPT_SESSION_REQUEST,
  SessionStatus.WAITING_FOR_VETTER_TO_PICKUP_CALL,
  SessionStatus.WAITING_FOR_VETTER_TO_PRESS_DIGIT,
  SessionStatus.WAITING_FOR_APPLICANT_TO_PICKUP_CALL,
  SessionStatus.VETTER_DID_NOT_PICKUP_CALL,
  SessionStatus.VETTER_REJECTED_SESSION_REQUEST,
  SessionStatus.VETTER_REQUEST_TIMED_OUT,
];

export const NON_REPEATABLE_STATUSES = [
  SessionStatus.WAITING_FOR_VETTER_TO_CONFIRM_APPLICANT_PICKED_UP,
  SessionStatus.SESSION_IN_PROGRESS,
  SessionStatus.APPLICANT_DROPPED_FROM_CALL,
  SessionStatus.VETTER_DROPPED_FROM_CALL,
  SessionStatus.WAITING_FOR_VETTER_TO_PROVIDE_FEEDBACK,
];

export const FAILED_STATUSES = [
  SessionStatus.APPLICANT_WAS_NOT_REACHABLE,
  SessionStatus.APPLICANT_DID_NOT_PICKUP_CALL,
  SessionStatus.VETTER_CLAIMS_APPLICANT_DID_NOT_PICKUP_CALL,
  SessionStatus.WAITING_FOR_VETTER_TO_CONFIRM_APPLICANT_PICKED_UP_TIMED_OUT,
  SessionStatus.WAITING_FOR_VETTER_TO_PROVIDE_FEEDBACK_TIMED_OUT,
  SessionStatus.CALL_DROPPED_TIMED_OUT,
  SessionStatus.SESSION_GLOBAL_TIMED_OUT,
  SessionStatus.SESSION_TERMINATED_BY_ADMIN,
  SessionStatus.SESSION_FINISHED_WITH_AN_ISSUE,
];

export const VETTED_STATUSES = [
  SessionStatus.APPLICANT_DID_NOT_PASS_KNOCKOUT,
  SessionStatus.SESSION_FINISHED_SUCCESSFULLY,
];

// 2. Filter by COMPLETED / IN_PROGRESS
export enum SessionStatusFilterEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type SessionStatusFilterType = keyof typeof SessionStatusFilterEnum;

export const IN_PROGRESS_STATUSES = [
  ...REPEATABLE_STATUSES,
  ...NON_REPEATABLE_STATUSES,
];

export const COMPLETED_STATUSES = [...FAILED_STATUSES, ...VETTED_STATUSES];

export const mapFilterToSessionStatus = (filter: SessionStatusFilterType) => {
  return filter === SessionStatusFilterEnum.IN_PROGRESS
    ? IN_PROGRESS_STATUSES
    : COMPLETED_STATUSES;
};

export const mapStatusToVetted = (status: SessionStatus) =>
  VETTED_STATUSES.find(s => s === status) ? 1 : 0;

export const mapRecommendedToPassed = (recommended: boolean) =>
  recommended ? 1 : 0;

export const mapInterviewer = (vetter: { clientId: string | null } | null) => {
  if (!vetter) return { internalInterviewer: 0, externalInterviewer: 0 };
  if (vetter && vetter?.clientId) {
    return { internalInterviewer: 1, externalInterviewer: 0 };
  } else {
    return { externalInterviewer: 1, internalInterviewer: 0 };
  }
};

export interface SessionPerformanceData {
  status: SessionStatus;
  recommended: boolean | null;
  jobOffer: {
    id: string;
    title: string;
    clientOfClient: string | null;
  };
  vetter: { clientId: string | null } | null;
}

export interface SessionQuestionAndAnswer {
  position: number;
  question: string;
  answers: string[];
  isDealBreaker: boolean;
}

export const parseClientSessionPerformanceData = (
  records: SessionPerformanceData[]
) => {
  const parsedRecords = records.reduce(
    (
      map,
      {
        status,
        recommended,
        jobOffer: { id, title: job, clientOfClient: client },
        vetter,
      }
    ) => {
      if (map.has(id)) {
        const val = map.get(id);
        val['vetted'] = val['vetted'] + mapStatusToVetted(status);
        val['passed'] =
          val['passed'] + mapRecommendedToPassed(recommended ?? false);
        val['internalInterviewer'] =
          val['internalInterviewer'] +
          mapInterviewer(vetter)['internalInterviewer'];
        val['externalInterviewer'] =
          val['externalInterviewer'] +
          mapInterviewer(vetter)['externalInterviewer'];
      } else {
        const val = {
          client,
          job,
          vetted: mapStatusToVetted(status),
          passed: mapRecommendedToPassed(recommended ?? false),
          ...mapInterviewer(vetter),
        };
        map.set(id, val);
      }

      return map;
    },
    new Map()
  );

  return Array.from(parsedRecords.values());
};

export const ISSUE_TYPE_OPTIONS = [
  {
    value: SessionReportedIssueType.CALL_LANDED_TO_APPLICANT_VOICEMAIL,
    text: "The call landed in the applicant's voicemail",
  },
  {
    value: SessionReportedIssueType.APPLICANT_CANNOT_SPEAK_NOW,
    text: 'The applicant cannot speak right now',
  },
  {
    value: SessionReportedIssueType.VETTER_HANG_UP_UNEXPECTEDLY,
    text: 'We got disconnected on my end',
  },
  {
    value: SessionReportedIssueType.APPLICANT_HANG_UP_UNEXPECTEDLY,
    text: 'The applicant dropped from the call unexpectedly',
  },
  {
    value: SessionReportedIssueType.COULD_NOT_HEAR_EACH_OTHER,
    text: 'We could not hear each other',
  },
  {
    value: SessionReportedIssueType.NEEDS_TRANSLATOR,
    text: 'Needs a translator',
  },
  { value: SessionReportedIssueType.OTHER, text: 'Other' },
];

export const getIssueDescriptionByIssueType = (
  issueType: SessionReportedIssueType
) => {
  return ISSUE_TYPE_OPTIONS.find(elem => elem.value === issueType);
};

export const scorecardUrl = (sessionId: string) =>
  `${process.env.NEXT_PUBLIC_API_URL}/public/session/pdf/${sessionId}`;

export const smsUrl = (conversationId?: string | null) => {
  return conversationId
    ? `${process.env.NEXT_PUBLIC_ADMIN_INTERFACE_URL}/sms?conversationId=${conversationId}`
    : null;
};

export type TranscriptionResult = {
  text: string;
  segments: {
    id: number;
    seek: number;
    start: number;
    end: number;
    text: string;
  }[];
};
