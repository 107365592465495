import React from 'react';
import { Card, Space, Text, theme, Title } from '@vette/ui-components';
import { UserPresenceEnum } from '@vette/data-access';
import { useUser } from '@vette/frontend-utils';
import { StatsChart } from './StatsChart';
import { ApplicantsList } from './ApplicantsList';

import * as S from './styles';

type Props = {
  userId: string;
  clientId: string;
};

export const DashboardInternalVetter: React.FC<Props> = ({
  userId,
  clientId,
}) => {
  const { user, loading } = useUser();

  return (
    <Space
      className="dashboard-interviewer"
      $padding="100px 36px"
      direction="vertical"
      size={53}
    >
      <S.LoadingHidden isLoading={loading}>
        <S.Profile href="/settings">
          <Text $color={theme.colors.white} $size={15} $weight={600}>
            {user?.firstName} {user?.lastName}
          </Text>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={user?.photoHighUrl || '/images/avatar.svg'} alt="profile" />
        </S.Profile>
      </S.LoadingHidden>
      <S.LoadingHidden isLoading={loading}>
        <Title $color={theme.colors.white} level={1}>
          Hi {user?.firstName}!
        </Title>
      </S.LoadingHidden>
      <Space $fullWidth direction="vertical" size={22}>
        <S.CoolCard
          disableFillAvailableHeight
          innerRowFullWidth
          noMaxWidth
          disableMargins
          disablePaddings
        >
          <StatsChart clientId={clientId} userId={userId} />
          <S.CirclesImage
            $active={
              user?.presence === UserPresenceEnum.BUSY ||
              user?.presence === UserPresenceEnum.ONLINE
            }
          />
        </S.CoolCard>

        <Card
          disableFillAvailableHeight
          innerRowFullWidth
          noMaxWidth
          disableMargins
        >
          <ApplicantsList userId={userId} clientId={clientId} />
        </Card>
      </Space>
    </Space>
  );
};
