import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
  forwardRef,
  Ref,
  useImperativeHandle,
  BaseSyntheticEvent,
  useCallback,
  useMemo,
} from 'react';
import { ConfigProvider, TablePaginationConfig, Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import { NetworkStatus } from '@apollo/client';
import { theme } from '../../theme';
import { PageCardRef } from '../../PageCard';
import { NoData, NoResults } from '../Empty';
import { Cell } from './Cell';
import { RowLinkProvider } from './RowLinkContext';
import * as S from './styles';

const Placeholder = () => (
  <S.Placeholder>
    <S.PlaceholderSkeleton isHeader />
    <S.PlaceholderSkeleton />
    <S.PlaceholderSkeleton />
  </S.Placeholder>
);

export type Props<TRecordType> = {
  disableHoverAnimation?: boolean;
  hasActiveFilters?: boolean;
  forbidExpand?: boolean;
  header?: ReactElement;
  headerGap?: number;
  rowPadding?: number;
  networkStatus?: NetworkStatus;
  loading?: boolean;
  pagination?: false | TablePaginationConfig;
  pageCardRef?: PageCardRef | null;
  getRowLink?: (record: TRecordType) => string | undefined;
  onRowClick?: (rowKey: string) => void;
  empty?: () => React.ReactNode;
} & TableProps<TRecordType>;

export type TableRef = {
  focusSelectedRow: (rowKey: string) => void;
};

const NOT_EXPANDABLE = {
  rowExpandable: () => false,
  expandable: { childrenColumnName: 'non_existing' },
};

export const Table = forwardRef(
  // eslint-disable-next-line @typescript-eslint/ban-types
  function <TRecordType extends object>(
    {
      disableHoverAnimation,
      pageCardRef,
      columns,
      dataSource,
      loading,
      networkStatus,
      hasActiveFilters,
      header,
      headerGap,
      rowPadding,
      pagination,
      getRowLink,
      onRowClick,
      forbidExpand,
      empty,
      ...props
    }: Props<TRecordType>,
    ref?: Ref<TableRef>
  ) {
    const tableRef = useRef<HTMLDivElement>(null);
    const rowsRef = useRef<Record<string, HTMLTableRowElement>>({});
    const [previousLoadedDataSource, setPreviousLoadedDataSource] =
      useState(dataSource);

    const isInitialLoading = networkStatus === NetworkStatus.loading;
    const isSubsequentLoading =
      networkStatus && networkStatus < NetworkStatus.poll && !isInitialLoading;

    useEffect(() => {
      if (!isInitialLoading && !isSubsequentLoading)
        setPreviousLoadedDataSource(dataSource);
    }, [dataSource, isInitialLoading, isSubsequentLoading]);

    const handleChange = useCallback<
      NonNullable<TableProps<TRecordType>['onChange']>
    >((_, __, ___, extra) => {
      if (extra.action === 'paginate') {
        if (pageCardRef) pageCardRef.scrollToTop();
      }
    }, []);

    const dataSourceMemoized = useMemo(
      () =>
        !dataSource?.length && isSubsequentLoading
          ? previousLoadedDataSource
          : dataSource,
      [dataSource, isSubsequentLoading, previousLoadedDataSource]
    );

    const handleRow = useCallback((args: any) => {
      return {
        onClick: (e: BaseSyntheticEvent) => {
          const selectDropdownClassNames = [
            'ant-select-item-option-content',
            'ant-select-selection-item',
          ];

          if (
            typeof e.target.className === 'string' &&
            !e.target.className
              .split(' ')
              .some((className: string) =>
                selectDropdownClassNames.includes(className)
              )
          )
            onRowClick?.(args?.id);
        },
      };
    }, []);

    const components = useMemo(
      () => ({
        body: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          row: (rowProps: any) => {
            const record = rowProps.children[0]?.props.record;
            const id = record?.id;
            return (
              <RowLinkProvider
                link={(() => {
                  if (typeof getRowLink === 'function' && dataSource?.length)
                    return getRowLink(record) || null;
                  return null;
                })()}
              >
                <tr
                  {...rowProps}
                  ref={el =>
                    id && (rowsRef.current[id] = el as HTMLTableRowElement)
                  }
                />
              </RowLinkProvider>
            );
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          cell: Cell,
        },
      }),
      [dataSourceMemoized]
    );

    useImperativeHandle(ref, () => ({
      focusSelectedRow: (rowKey: string) => {
        setTimeout(() => {
          const row = rowsRef.current[rowKey];
          if (row) {
            row.scrollIntoView({ block: 'center', inline: 'nearest' });
            // TODO: fix animation its not working
            row.animate(
              { backgroundColor: [theme.colors.yellow20, theme.colors.white] },
              10000
            );
          }
        }, 0);
      },
    }));

    const renderEmpty = useMemo(
      () => (empty ? empty : hasActiveFilters ? NoResults : NoData),
      [empty, hasActiveFilters]
    );

    return (
      <ConfigProvider renderEmpty={renderEmpty}>
        <S.Wrapper
          $disableHoverAnimation={disableHoverAnimation}
          $gap={headerGap}
          $rowPadding={rowPadding}
          $forbidExpand={forbidExpand}
        >
          {header}
          {loading || isInitialLoading ? (
            <Placeholder />
          ) : (
            <AntTable
              rowKey="id"
              ref={tableRef}
              columns={columns}
              dataSource={dataSourceMemoized}
              loading={isSubsequentLoading}
              pagination={pagination}
              onChange={handleChange}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onRow={handleRow}
              components={components}
              expandable={NOT_EXPANDABLE}
              {...props}
            />
          )}
        </S.Wrapper>
      </ConfigProvider>
    );
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
) as <TRecordType extends object>(
  p: Props<TRecordType> & { ref?: Ref<TableRef> }
) => ReactElement;

(Table as any).displayName = 'VetteTable';
