import React from 'react';
import { SessionStatusEnum } from '@vette/data-access';
import { SessionStatusBadge, Space, Text } from '@vette/ui-components';
import { Row } from 'antd';

type Props = {
  status: SessionStatusEnum;
  recommended: boolean | null;
  stat: number | null;
  tooltip: string;
};

export const LegendItem: React.FC<Props> = ({
  status,
  recommended,
  stat,
  tooltip,
}) => {
  return (
    <Space key={`${status}${recommended}`} direction="vertical" size={4}>
      <Text $size={15} $weight={600}>
        {stat || '-'}
      </Text>
      <Row>
        <SessionStatusBadge
          status={status}
          recommended={recommended}
          tooltipText={tooltip}
        />
      </Row>
    </Space>
  );
};
