import { Space } from '@vette/ui-components';
import { Skeleton } from 'antd';
import { SkeletonButtonProps } from 'antd/lib/skeleton/Button';
import { columns as originalColumns } from '../columns';

export const columns = ({ loading }: { loading?: boolean }) => {
  const render = (props: SkeletonButtonProps) => {
    return <Skeleton.Button shape="square" {...props} active={loading} />;
  };

  return [
    ...originalColumns.map(column => ({
      ...column,
      render: () => {
        switch (column.key) {
          case 'status':
            return render({
              style: { width: '64px', height: '22px' },
              size: 'large',
              shape: 'round',
            });
          case 'job':
            return (
              <Space direction="horizontal" size={4}>
                <Skeleton.Avatar style={{ width: '14px', height: '14px' }} />
                {render({ style: { width: column.width } })}
              </Space>
            );

          case 'createdAt':
            return (
              <>
                {render({ style: { width: column.width } })}
                <br />
                {render({ style: { width: '45px' } })}
              </>
            );
          default:
            return render({ style: { width: column.width } });
        }
      },
    })),
  ];
};
